import {Injectable} from '@angular/core';
import {HistoryMonthDto} from '../../../../shared/models/history-month.dto';

@Injectable({
  providedIn: 'root'
})
export class RefactorHistoryDataService {

  constructor() {
  }

  refactorChartData(data: HistoryMonthDto[]): any[] {
    let refactoredData: HistoryMonthDto[] = [];
    const firstMonth = data[0].month;

    // console.log('firstMonth', firstMonth);

    if (data.length >= 12) {
      return data;
    }

    if (firstMonth > 1) {
      const firstPartData = [];
      for (let i = 1; i < firstMonth; i++) {
        firstPartData.push(new HistoryMonthDto(i, 0, 0));
      }
      refactoredData = firstPartData.concat(data);
    } else {
      refactoredData = data;
    }

    for (let i = refactoredData.length + 1; i <= 12; i++) {
      refactoredData = [...refactoredData, new HistoryMonthDto(i, 0, 0)];
    }

    return refactoredData;
  }
}
