export class HistoryMonthDto {

  constructor(month: number, initialMinutes: number, remainingMinutes: number) {
    this.month = month;
    this.initialMinutes = initialMinutes;
    this.remainingMinutes = remainingMinutes;
  }

  month: number;
  initialMinutes: number;
  remainingMinutes: number;
}
